<template>
  <div>
   

    <vs-card >
      <b-row>
      <b-col  cols="12"
          md="4"></b-col>
      <b-col
          cols="12"
          md="3"
          style="text-align:center"
        >
          <b-form-group
            :label="$t('DoctorName')"
            label-for="DoctorName"
          >
            <b-form-input
              id="DoctorName"
              v-model="search.DoctorName"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </vs-card>

    <b-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="doctors"
          >

            <template slot="thead">
              <vs-th >{{ $t("Doctor") }}</vs-th>
              <vs-th >{{ $t("Specialties") }}</vs-th>
              <vs-th>{{ $t("SubSpecialties") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click="$router.push('/DoctorProfile/' + tr.DoctorID)">
                <vs-tr class="cursor-pointer hover:text-primary"  >
                  <vs-td>
                      
                      <div>
           
           <imageLazy
                     :imageStyle="{ 'border-radius': '20px','width':'40px','height':'40px',size:'100px' }"
                     :imageClass="'doctorImage'"
                     :src="baseURL + tr.ProfileImagePath"
                     placeHolderType="doctor"
                   />
         </div>

         <u>{{ $t("Dr") + "/" }}
            <span v-if="tr.FullName"> {{ tr.FullName }} </span>
            <span v-else> {{ tr.DoctorName }} </span>

           </u>
                  </vs-td>
                  <vs-td>
                    <div class="vx-col lg:w-1/2" v-for="item in tr.specilties" :key="item.ID">
                 {{item.HospitalSpecialty.Specialty.Name}}
           </div>
                  </vs-td>
                
                  <vs-td>
                    <div v-for="item in tr.SubSpecilties" :key="item.ID">
                 {{item.HospitalSpecialty.Specialty.Name}}
           </div>
                  </vs-td>
                   </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </b-card>

 
      <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </div>
</template>
<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
import {
BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow, BFormGroup, BFormInput
} from "bootstrap-vue";
export default {
  components: {
    imageLazy,
    BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow, BFormGroup, BFormInput
  },
  data() {
    return {
      EnglishLan:false,
      search: {},
      CurrentPage: 1,
      baseURL: domain,
      doctorList:[],
      CountPerPage:7
    };
  },
  methods: {
    SearchDoctor() {},
    back() {
      this.$router.go(-1);
    },
  
  },
  computed: {
    doctors() {
      if (this.search.DoctorName) {
        debugger;
        var vm = this;
        return this.doctorList.filter(function (obj) {
          return obj.DoctorName.toLowerCase().indexOf(vm.search.DoctorName.toLowerCase()) > -1;
        });
      } else return this.doctorList;
      // return this.doctorList.filter(ele=>
      //  !this.search.DoctorName||
      //     ele.DoctorName.toLowerCase().includes(this.search.DoctorName.toLowerCase()) 
      // );
    },
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    this.$store.dispatch("HospitalList/SearchHospitalDoctors", {
      HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
    }).then(res=>{
      debugger
        var model = res.data.Data;
        var doctorLists=[];
        model.forEach(ele => {
            if(doctorLists.findIndex(r=>r.DoctorID==ele.Doctor.ID)==-1)
            {
                var obj={};
                obj.DoctorName = ele.Doctor.Name;
                 obj.FullName = ele.Doctor.FullName;
                obj.DoctorID =  ele.Doctor.ID;
                obj.ProfileImagePath = ele.Doctor.ProfileImagePath;

                obj.specilties=model.filter(b=>
                    b.Doctor.ID == ele.Doctor.ID && !b.HospitalSpecialty.Specialty.ParentSpecialtyID
                );
                obj.SubSpecilties=model.filter(b=>
                    b.Doctor.ID == ele.Doctor.ID && b.HospitalSpecialty.Specialty.ParentSpecialtyID
                );
                doctorLists.push(obj);
            }
        });
        this.doctorList=doctorLists;
    });
  },
};
</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>